import { Map } from 'immutable';
import AdminDataActions from '../actions/AdminDataAction';

// const initialState = new Map({
//   callRecords: [],
// });

const initialState = {
  postCallRecordData: {},
  postCallRecordError: {},
  postPhoneNumberData: {},
  postPhoneNumberError: {},
  postworkOrderNumberData: {},
  postworkOrderNumberError: {},

  editWorkSiteData: {},
  editWorkSiteError: {},
  editCallRecordData: {},
  editCallRecordError: {},
  editPhoneNumberData: {},
  editPhoneNumberError: {},
  editworkOrderNumberData: {},
  editAdminPasswordData: {},
  editAdminPasswordError: {},
  editAdminDetailsData: {},
  editAdminDetailsError: {},

  updateCheckInCallRecordData: {},
  updateCheckInCallRecordError: {},

  deleteWorkSiteData: {},
  deleteWorkSiteError: {},
  inActiveWorkSiteData: {},
  inActiveWorkSiteError: {},
  deleteCallRecordData: {},
  deleteCallRecordError: {},
  deletePhoneNumberData: {},
  deletePhoneNumberError: {},
  deleteworkOrderNumberData: {},
  deleteworkOrderNumberError: {},

  callRecords: [],
  getCallRecordsError: {},
  removedCallRecords: [],
  getRemovedCallRecordsError: {},
  getCompletedVisitsData: [],
  getCompletedVisitsError: {},
  getCheckInVisitsData: [],
  getCheckInVisitsError: {},
  getWorkOrderSummaryData: [],
  getWorkOrderSummaryError: {},
  getCallRecordsByWorkOrderNumberData: [],
  getCallRecordsByWorkOrderNumberError: {},

  workOrdersData: [],
  getWorkOdersError: {},
  phoneNumberData: [],
  getPhoneNumberError: {},
  logRecordsData: [],
  getLogRecordsError: {},
  getAdminData: {},
  getAdminError: {},
  getManagerData: {},
  getManagerError: {},
};

export default function AdminData(state = initialState, action) {
  switch (action.type) {
    // GET Method
    case AdminDataActions.GET_CALL_RECORDS_DATA:
      return { ...state, callRecords: action?.payload };
    case AdminDataActions.GET_CALL_RECORDS_ERROR:
      return { ...state, getCallRecordsError: action?.payload };
    case AdminDataActions.GET_REMOVED_CALL_RECORDS_DATA:
      return { ...state, removedCallRecords: action?.payload };
    case AdminDataActions.GET_REMOVED_CALL_RECORDS_ERROR:
      return { ...state, getRemovedCallRecordsError: action?.payload };
    case AdminDataActions.GET_COMPLETED_VISITS_DATA:
      return { ...state, getCompletedVisitsData: action?.payload };
    case AdminDataActions.GET_COMPLETED_VISITS_ERROR:
      return { ...state, getCompletedVisitsError: action?.payload };
    case AdminDataActions.GET_CHECK_IN_VISITS_DATA:
      return { ...state, getCheckInVisitsData: action?.payload };
    case AdminDataActions.GET_CHECK_IN_VISITS_ERROR:
      return { ...state, getCheckInVisitsError: action?.payload };
    case AdminDataActions.GET_WORK_ORDER_SUMMARY_DATA:
      return { ...state, getWorkOrderSummaryData: action?.payload };
    case AdminDataActions.GET_WORK_ORDER_SUMMARY_ERROR:
      return { ...state, getWorkOrderSummaryError: action?.payload };

    case AdminDataActions.GET_ALL_WORK_ORDER_NUMBER_DATA:
      return { ...state, workOrdersData: action?.payload };
    case AdminDataActions.GET_ALL_WORK_ORDER_NUMBER_ERROR:
      return { ...state, getWorkOdersError: action?.payload };
    case AdminDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA:
      return { ...state, getCallRecordsByWorkOrderNumberData: action?.payload };
    case AdminDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR:
      return { ...state, getCallRecordsByWorkOrderNumberError: action?.payload };

    case AdminDataActions.GET_ALL_PHONE_NUMBER_DATA:
      return { ...state, phoneNumberData: action?.payload };
    case AdminDataActions.GET_ALL_PHONE_NUMBER_ERROR:
      return { ...state, getPhoneNumberError: action?.payload };
    case AdminDataActions.GET_ALL_LOG_RECORDS_DATA:
      return { ...state, logRecordsData: action?.payload };
    case AdminDataActions.GET_ALL_LOG_RECORDS_ERROR:
      return { ...state, getLogRecordsError: action?.payload };

    case AdminDataActions.GET_ADMIN_DATA:
      return { ...state, getAdminData: action?.payload };
    case AdminDataActions.GET_ADMIN_ERROR:
      return { ...state, getAdminError: action?.payload };
    case AdminDataActions.GET_WORK_SITE_MANAGER_DATA:
      return { ...state, getManagerData: action?.payload };
    case AdminDataActions.GET_WORK_SITE_MANAGER_ERROR:
      return { ...state, getManagerError: action?.payload };

    // POST Method
    case AdminDataActions.POST_CALL_RECORD_DATA:
      return { ...state, postCallRecordData: action?.payload };
    case AdminDataActions.POST_CALL_RECORD_ERROR:
      return { ...state, postCallRecordError: action?.payload };
    case AdminDataActions.POST_PHONE_NUMBER_DATA:
      return { ...state, postPhoneNumberData: action?.payload };
    case AdminDataActions.POST_PHONE_NUMBER_ERROR:
      return { ...state, postPhoneNumberError: action?.payload };
    case AdminDataActions.POST_WORK_ORDER_NUMBER_DATA:
      return { ...state, postWorkOrderNumberData: action?.payload };
    case AdminDataActions.POST_WORK_ORDER_NUMBER_ERROR:
      return { ...state, postWorkOrderNumberError: action?.payload };

    // EDIT Method
    case AdminDataActions.EDIT_WORK_SITE_DATA:
      return { ...state, editWorkSiteData: action?.payload };
    case AdminDataActions.EDIT_WORK_SITE_ERROR:
      return { ...state, editWorkSiteError: action?.payload };
    case AdminDataActions.EDIT_CALL_RECORD_DATA:
      return { ...state, editCallRecordData: action?.payload };
    case AdminDataActions.EDIT_CALL_RECORD_ERROR:
      return { ...state, editCallRecordError: action?.payload };
    case AdminDataActions.EDIT_PHONE_NUMBER_DATA:
      return { ...state, editPhoneNumberData: action?.payload };
    case AdminDataActions.EDIT_PHONE_NUMBER_ERROR:
      return { ...state, editPhoneNumberError: action?.payload };
    case AdminDataActions.EDIT_WORK_ORDER_NUMBER_DATA:
      return { ...state, editWorkOrderNumberData: action?.payload };
    case AdminDataActions.EDIT_WORK_ORDER_NUMBER_ERROR:
      return { ...state, editWorkOrderNumberError: action?.payload };

    case AdminDataActions.EDIT_ADMIN_PASSWORD_DATA:
      return { ...state, editAdminPasswordData: action?.payload };
    case AdminDataActions.EDIT_ADMIN_PASSWORD_ERROR:
      return { ...state, editAdminPasswordError: action?.payload };

    case AdminDataActions.EDIT_ADMIN_DETAILS_DATA:
      return { ...state, editAdminDetailsData: action?.payload };
    case AdminDataActions.EDIT_ADMIN_DETAILS_ERROR:
      return { ...state, editAdminDetailsError: action?.payload };

    case AdminDataActions.UPDATE_CHECK_IN_CALL_RECORD_DATA:
      return { ...state, updateCheckInCallRecordData: action?.payload };
    case AdminDataActions.UPDATE_CHECK_IN_CALL_RECORD_ERROR:
      return { ...state, updateCheckInCallRecordError: action?.payload };

    // DELETE Method
    case AdminDataActions.DELETE_WORK_SITE_DATA:
      return { ...state, deleteWorkSiteData: action?.payload };
    case AdminDataActions.DELETE_WORK_SITE_ERROR:
      return { ...state, deleteWorkSiteError: action?.payload };
    case AdminDataActions.INACTIVE_WORK_SITE_DATA:
      return { ...state, inActiveWorkSiteData: action?.payload };
    case AdminDataActions.INACTIVE_WORK_SITE_ERROR:
      return { ...state, inActiveWorkSiteError: action?.payload };
    case AdminDataActions.DELETE_CALL_RECORD_DATA:
      return { ...state, deleteCallRecordData: action?.payload };
    case AdminDataActions.DELETE_CALL_RECORD_ERROR:
      return { ...state, deleteCallRecordError: action?.payload };
    case AdminDataActions.DELETE_PHONE_NUMBER_DATA:
      return { ...state, deletePhoneNumberData: action?.payload };
    case AdminDataActions.DELETE_PHONE_NUMBER_ERROR:
      return { ...state, deletePhoneNumberError: action?.payload };
    case AdminDataActions.DELETE_WORK_ORDER_NUMBER_DATA:
      return { ...state, deleteWorkOrderNumberData: action?.payload };
    case AdminDataActions.DELETE_WORK_ORDER_NUMBER_ERROR:
      return { ...state, deleteWorkOrderNumberError: action?.payload };

    default:
      return { ...state };
  }
}
