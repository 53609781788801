import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserType, setUserId } from '../../api-actions/adminAuthActions';

// AuthenticateRoute component
const AuthenticateRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userPermissions = {
    admin: ['/admin/dashboard', '/admin/all-visits', '/admin/visits-logs', '/admin/removed-visits', '/admin/work-order', 'admin/work-order/:id', '/admin/phone-number', '/admin/phone-number-verified', '/admin/phone-number-unverified', '/admin/corporate', '/admin/log-records', '/admin/profile', '/admin/settings', '/admin/reports/completed-visits', '/admin/reports/check-in-visits', '/admin/reports/work-order-summary', '/admin/active-sites', '/admin/inactive-sites', '/admin/edits-to-active-sites', '/admin/edits-to-inactive-sites'],
    corporate: ['/corporate/dashboard', '/corporate/visits-logs','/corporate/removed-visits','/corporate/all-visits',  '/corporate/work-order', '/corporate/phone-number', '/corporate/phone-number-verified', '/corporate/phone-number-unverified', '/corporate/profile', '/corporate/settings', '/corporate/work-order/:id', '/corporate/reports/completed-visits', '/corporate/reports/check-in-visits', '/corporate/reports/work-order-summary', '/corporate/active-sites', '/corporate/inactive-sites', '/corporate/edits-to-active-sites', '/corporate/edits-to-inactive-sites'],
    worksite: ['/worksite/dashboard','/worksite/all-visits','/worksite/visits-logs','/worksite/removed-visits', '/worksite/visits', '/worksite/work-order', '/worksite/phone-number', '/worksite/phone-number-verified', '/worksite/phone-number-unverified', '/worksite/profile', '/worksite/settings', '/worksite/work-order/:id', '/worksite/reports/completed-visits', '/worksite/reports/check-in-visits', '/worksite/reports/work-order-summary'],
  }

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');

    if (!token) {
      if (location.pathname.startsWith('/worksite/reset-password/')) {
        return children;
      } else if (location.pathname.startsWith('/corporate/reset-password/')) {
        return children;
      }

      if (location.pathname !== '/login' && location.pathname !== '/worksite/forgot-password' && location.pathname !== '/corporate/forgot-password') {
        navigate('/login');
      }
      return;
    }

    const payload = token && token.split('.')[1];
    const decodedToken = payload && JSON.parse(atob(payload));

    const userType = decodedToken?.userType?.toLowerCase();
    const userId = decodedToken.userId;

    const allowedRoutes = userPermissions[userType] || [];
    const isWorkOrderRoute = location?.pathname.startsWith('/worksite/work-order/') || location?.pathname.startsWith('/corporate/work-order/') || location?.pathname.startsWith('/admin/work-order/');
    const currentTime = Date.now() / 1000;

    if (location?.pathname === '/login' && token && decodedToken?.exp >= currentTime) {
      return navigate(`${userType}/all-visits`);
    }

    if (location?.pathname === '/' && token && decodedToken?.exp >= currentTime && location?.pathname !== '/login') {
      return navigate(`${userType}/all-visits`);
    }

    if ((!allowedRoutes.includes(location?.pathname) && !isWorkOrderRoute) && token && decodedToken?.exp >= currentTime) {
      return navigate(`${userType}/all-visits`);
    }

    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token');
      return navigate('/login');
    }

    dispatch(setUserType(userType))
    dispatch(setUserId(userId))
  }, [navigate, location]);

  return children;
};

export default AuthenticateRoute;
