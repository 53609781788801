import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-form-validator-core";

import "./blank_page_style.css";

import InputWithLabel from "../../Forms/Elements/InputWithLabel";

import { adminLogin, corporateLogin, resetUserData, resetUserErrorData, worksiteLogin } from "../../../api-actions/adminAuthActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import {jwtDecode} from "jwt-decode";

const content = ({ }) => {
  const [simpleTabs, setSimpleTabs] = useState([false, false, true]);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMeValue, setRememberMeValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logInSuccess = useSelector((state) => state.AdminAuthData.userData);
  const logInError = useSelector((state) => state.AdminAuthData.userLogError);


  useEffect(() => {
    if (logInSuccess && Object.keys(logInSuccess)?.length > 0) {
      const jwtToken = logInSuccess?.data?.jwtToken;
      localStorage.setItem('jwtToken', logInSuccess?.data?.jwtToken);
      localStorage.setItem('adminName', logInSuccess?.data?.user?.name);
      toast.success("Login Successful")

      // Decode token to get expiration time
      const decodedToken = jwtDecode(jwtToken);
      
      const expirationTime = decodedToken?.exp * 1000; // Convert from seconds to milliseconds
    
      const currentTime = Date.now();
      
      const timeUntilExpiration = expirationTime - currentTime;
      localStorage.setItem('tokenExpirationTime', expirationTime);
    
      if (timeUntilExpiration > 0) {
        setTimeout(() => {
          handleAutoLogout();
        }, timeUntilExpiration);
      }

      const handleAutoLogout = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('adminName');
        localStorage.removeItem('tokenExpirationTime');
        toast.error('Session expired, please log in again.');
        navigate('/login');
      };

      // Navigate after a short delay
      setTimeout(() => {
        dispatch(resetUserData());
        if(simpleTabs[0]){
        navigate('admin/all-visits');
        }else if(simpleTabs[1]){
          navigate('corporate/all-visits');
        }else{
          navigate('worksite/all-visits');
        }
      }, 1000);
    }
  }, [logInSuccess]);

  useEffect(() => {
    if (logInError && Object.keys(logInError)?.length > 0) {
      toast.error(logInError?.message);
      dispatch(resetUserErrorData());
    }
  }, [logInError]);

  const handleRememberMe = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setRememberMeValue(value);
  }

  let handleAdminFormSubmit = () => {
    //Call this function on form submit with no errors
    if (userName !== "" && password !== "") {
      dispatch(adminLogin({
        adminEmail: userName,
        adminPassword: password,
        rememberMe: rememberMeValue,
      }))
    } else {
      toast.error('Please fill all required fields')
    }
  };

  let handleCorporateFormSubmit = () => {
    //Call this function on form submit with no errors
    if (userName !== "" && password !== "") {
      dispatch(corporateLogin({
        corporateEmail: userName,
        corporatePassword: password,
        rememberMe: rememberMeValue,
      }))
    } else {
      toast.error('Please fill all required fields')
    }
  };

  let handleWorksiteFormSubmit = () => {
    //Call this function on form submit with no errors
    if (userName !== "" && password !== "") {
      dispatch(worksiteLogin({
        worksiteEmail: userName,
        worksitePassword: password,
        rememberMe: rememberMeValue,
      }))
    } else {
      toast.error('Please fill all required fields')
    }
  };

  return (
    <div className="login-wrapper ">
      <div className="bg-pic">
        <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
          <h1 className="semi-bold text-white">
            Real-time call logging
          </h1>
          <p className="small">
            Our advanced call record system simplifies employee attendance management. With just a phone call, your team can check in and out securely, while you gain valuable insights into work patterns and productivity.
          </p>
        </div>
      </div>
      <div className="login-container bg-white">
        <div className="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40 cusmobpad">
          <img
            src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
            alt="FM Guardian"
            data-src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
            data-src-retina="/assets/img/fmglogo.png"
            width={'75%'}
            className="mx-auto"
          />

          {/* <p className="mw-80 m-t-5">Sign in </p> */}

          <div className="card card-borderless m-t-25">
            <ul
              className="nav nav-tabs nav-tabs-simple d-md-flex d-lg-flex d-xl-flex"
              role="tablist"
              data-init-reponsive-tabs="dropdownfx"
            >
              <li
                className="nav-item w-100"
                onClick={() => setSimpleTabs([true, false, false])}
              >
                <a
                  className={simpleTabs[0] ? "active text-center" : "text-center"}
                  data-toggle="tab"
                  role="tab"
                  data-target="#tab2hellowWorld"
                  aria-selected={simpleTabs[0] ? "true" : ""}
                  href=""
                  onClick={(e) => e.preventDefault()}
                >
                  FM Guardian
                </a>
              </li>
              <li
                className="nav-item  w-100"
                onClick={() => setSimpleTabs([false, true, false])}
              >
                <a
                  className={simpleTabs[1] ? "active text-center" : "text-center"}
                  href=""
                  data-toggle="tab"
                  role="tab"
                  data-target="#tab2FollowUs"
                  aria-selected={simpleTabs[1] ? "true" : ""}
                  onClick={(e) => e.preventDefault()}
                >
                  Corporate
                </a>
              </li>
              <li
                className="nav-item  w-100"
                onClick={() => setSimpleTabs([false, false, true])}
              >
                <a
                  className={simpleTabs[2] ? "active text-center" : "text-center"}
                  href=""
                  data-toggle="tab"
                  role="tab"
                  data-target="#tab2Inspire"
                  aria-selected={simpleTabs[2] ? "true" : ""}
                  onClick={(e) => e.preventDefault()}
                >
                  Worksite
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className={`tab-pane ${simpleTabs[0] ? "active" : ""}`}
                id="tab2hellowWorld"
              >
                <h2 className="p-t-10">
                  <span className="small">Sign in to</span> <br /> FM Guardian
                </h2>

                <ValidatorForm
                  instantValidate={true}
                  onSubmit={handleAdminFormSubmit}
                  className="p-t-15"
                >
                  <div className="form-group  form-group-default required py-2">
                    <InputWithLabel
                      onChange={(e) => setUserName(e.target.value)}
                      name="email"
                      value={userName}
                      className={'form-control'}
                      // label={'Login'}
                      placeholder="Email"
                      require="true"
                    />
                  </div>
                  <div className="form-group  form-group-default required py-2 d-flex align-items-center justify-content-between">
                    <InputWithLabel
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      value={password}
                      className={'form-control'}
                      // label={'Password'}
                      placeholder="Password"
                      require="true"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {showPassword ? <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i> :
                        <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 no-padding sm-p-l-10">
                      <div className="form-check">
                        <input type="checkbox" value={rememberMeValue} onChange={handleRememberMe} id="checkbox1" />
                        <label htmlFor="checkbox1">Remember me</label>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end">
                      <button
                        aria-label=""
                        className="btn btn-primary btn-lg m-t-10"
                        type="submit"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                  {/* <div className="m-b-5 m-t-30">
              <a href="javascript:void(0);" className="normal">
                Lost your password?
              </a>
            </div>
            <div>
              <a href="javascript:void(0);" className="normal">
                Not a member yet? Signup now.
              </a>
            </div> */}
                </ValidatorForm>
              </div>
              <div
                className={`tab-pane ${simpleTabs[1] ? "active" : ""}`}
                id="tab2FollowUs"
              >

                <h2 className="p-t-10">
                  <span className="small">Sign in to</span> <br /> Corporate
                </h2>

                <ValidatorForm
                  instantValidate={true}
                  onSubmit={handleCorporateFormSubmit}
                  className="p-t-15"
                >
                  <div className="form-group  form-group-default required py-2">
                    <InputWithLabel
                      onChange={(e) => setUserName(e.target.value)}
                      name="email"
                      value={userName}
                      className={'form-control'}
                      // label={'Login'}
                      placeholder="Email"
                      require="true"
                    />
                  </div>
                  <div className="form-group  form-group-default required py-2 d-flex align-items-center justify-content-between">
                    <InputWithLabel
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      value={password}
                      className={'form-control'}
                      // label={'Password'}
                      placeholder="Password"
                      require="true"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {showPassword ? <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i> :
                        <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 no-padding sm-p-l-10">
                      <div className="form-check">
                        <input type="checkbox" value={rememberMeValue} onChange={handleRememberMe} id="checkbox2" />
                        <label htmlFor="checkbox2">Remember me</label>
                         <div className="m-b-5 m-t-15">
                          <Link to="/corporate/forgot-password" className="normal">
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end">
                      <button
                        aria-label=""
                        className="btn btn-primary btn-lg m-t-10"
                        type="submit"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                  {/* <div className="m-b-5 m-t-30">
              <a href="javascript:void(0);" className="normal">
                Lost your password?
              </a>
            </div>
            <div>
              <a href="javascript:void(0);" className="normal">
                Not a member yet? Signup now.
              </a>
            </div> */}
                </ValidatorForm>
              </div>
              <div
                className={`tab-pane ${simpleTabs[2] ? "active" : ""}`}
                id="tab2Inspire"
              >
                <h2 className="p-t-10">
                  <span className="small">Sign in to</span> <br /> Worksite
                </h2>
                <ValidatorForm
                  instantValidate={true}
                  onSubmit={handleWorksiteFormSubmit}
                  className="p-t-15"
                >
                  <div className="form-group  form-group-default required py-2">
                    <InputWithLabel
                      onChange={(e) => setUserName(e.target.value)}
                      name="email"
                      value={userName}
                      className={'form-control'}
                      // label={'Login'}
                      placeholder="Email"
                      require="true"
                    />
                  </div>
                  <div className="form-group  form-group-default required py-2 d-flex align-items-center justify-content-between">
                    <InputWithLabel
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      value={password}
                      className={'form-control'}
                      // label={'Password'}
                      placeholder="Password"
                      require="true"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                      {showPassword ? <i className="pg-icon" style={{ cursor: 'pointer' }}>eye</i> :
                        <i className="pg-icon" style={{ cursor: 'pointer' }}>eye_off</i>}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 no-padding sm-p-l-10">
                      <div className="form-check">
                        <input type="checkbox" value={rememberMeValue} onChange={handleRememberMe} id="checkbox3" />
                        <label htmlFor="checkbox3">Remember me</label>
                        <div className="m-b-5 m-t-15">
                          <Link to="/worksite/forgot-password" className="normal">
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end">
                      <button
                        aria-label=""
                        className="btn btn-primary btn-lg m-t-10"
                        type="submit"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                  <div className="m-b-5 m-t-30">

                  </div>
                  {/* <div>
              <a href="javascript:void(0);" className="normal">
                Not a member yet? Signup now.
              </a>
            </div> */}
                </ValidatorForm>
              </div>
            </div>
          </div>




          <div className="pull-bottom sm-pull-bottom w-100">
            <div className="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
              <div className="col-sm-9 no-padding m-t-10">
                <p className="small-text normal hint-text">
                  ©{(new Date().getFullYear())} FM Guardian. All Rights Reserved.<br />
                  <a href="javascript:void(0);">Cookie Policy</a>,{' '}
                  <a href="javascript:void(0);"> Privacy and Terms</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default content;
