import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageContainer from '../UIElements/Containers';
import SlideUpModal from '../Modals/SlideUpModal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import { useLocation } from 'react-router-dom';
import {
  getWorkSitesForCorporate,
  resetGetPhoneNumberErrorData,
  getAllPhoneNumbers,
  deletePhoneNumber,
  editPhoneNumber,
  postPhoneNumber,
  resetPostPhoneNumberData,
  resetPostPhoneNumberErrorData,
  resetEditPhoneNumberData,
  resetEditPhoneNumberErrorData,
  resetDeletePhoneNumberData,
  resetDeletePhoneNumberErrorData,
} from '../../api-actions/corporateDataAction';
import { resetWorkSiteErrorData } from '../../api-actions/workSiteDataAction';
import GroupSelect from '../Forms/Elements/GroupSelect';
import { FiShare } from 'react-icons/fi';
import Loader from '../Loader/Loader';
import NoData from '../NoData/NoData';
import { RiShareBoxFill } from 'react-icons/ri';

const PhoneNumber = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();

  const userId = useSelector((state) => state.AdminAuthData.userId);

  const workSitesForCorporateData = useSelector((state) => state.CorporateData.getWorkSitesForCorporateData);
  const workSitesForCorporateError = useSelector((state) => state.CorporateData.getWorkSitesForCorporateError);

  const phoneNumberData = useSelector((state) => state.CorporateData.getAllPhoneNumbersData);
  const phoneNumberErrorData = useSelector((state) => state.CorporateData.getAllPhoneNumbersError);

  const postPhoneNumberData = useSelector((state) => state.AdminData.postPhoneNumberData);
  const postPhoneNumberError = useSelector((state) => state.AdminData.postPhoneNumberError);
  const editPhoneNumberData = useSelector((state) => state.AdminData.editPhoneNumberData);
  const editPhoneNumberError = useSelector((state) => state.AdminData.editPhoneNumberError);
  const deletePhoneNumberData = useSelector((state) => state.AdminData.deletePhoneNumberData);
  const deletePhoneNumberError = useSelector((state) => state.AdminData.deletePhoneNumberError);

  const [phoneNumberWithIndex, setPhoneNumberWithIndex] = useState([])
  const [exportPhoneNumberData, setExportPhoneNumberData] = useState()
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentPhoneNumberId, setCurrentPhoneNumberId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    storeNo: '',
    phoneNo: '',
  });

  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [worksiteId, setWorksiteId] = useState('');
  const [formWorksiteId, setformWorksiteId] = useState('');
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  // const options = {
  //   sizePerPageRenderer
  // };

  const options = {
    page: currentPage, // Ensure currentPage is used
    sizePerPage: sizePerPage, // Set the current size per page
    onSizePerPageChange: (sizePerPage, page) => {
      setSizePerPage(sizePerPage);
      setCurrentPage(page); // Update the page when size changes
    },
    onPageChange: (page) => setCurrentPage(page),
    sizePerPageRenderer,
  };

  useEffect(() => {
    setIsLoading(true)
    if (userId) {
      dispatch(getAllPhoneNumbers(jwtToken, userId));
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
    }
  }, [userId])

  useEffect(() => {
    const { phoneNo, storeNo } = filters; // Extract filters from state

    let filteredData = phoneNumberData;

    // Pathname based filtering
    if (location?.pathname === "/corporate/phone-number-unverified") {
      filteredData = filteredData.filter((item) => Number(item?.verified) === 0);
    } else if (location?.pathname === "/corporate/phone-number-verified") {
      filteredData = filteredData.filter((item) => Number(item?.verified) === 1);
    } else if (location?.pathname === "/corporate/phone-number-all") {
      // No additional filter, just use all data
      filteredData = phoneNumberData;
    }

    // Apply worksiteId filter if provided
    if (worksiteId?.length > 0) {
      filteredData = filteredData.filter(data => data.worksiteId === worksiteId);
    }

    // Apply phoneNo filter if provided
    if (phoneNo) {
      filteredData = filteredData.filter(data =>
        data.phoneNumber?.toLowerCase().includes(phoneNo.toLowerCase())
      );
    }

    // Apply storeNo filter if provided
    if (storeNo) {
      filteredData = filteredData.filter(data =>
        data.storeNumber?.toLowerCase().includes(storeNo.toLowerCase())
      );
    }

    // Add index and update state
    setPhoneNumberWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 })));
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [filters, location.pathname, phoneNumberData, worksiteId]);


  useEffect(() => {
    if (phoneNumberErrorData && Object.keys(phoneNumberErrorData)?.length > 0) {
      dispatch(resetGetPhoneNumberErrorData());
      setIsLoading(false)
    }
  }, [phoneNumberErrorData]);

  useEffect(() => {
    if (workSitesForCorporateData) {
      setWorksiteOptions([]);
      setWorksiteOptionsForFilter([]);
      const worksiteData = workSitesForCorporateData?.map((record) => ({
        value: record?.id,
        label: record?.storeName,
      }));

      setWorksiteOptions(Array.from(new Map(worksiteData.map(item => [item.value, item])).values()))

      const uniqueArray = Array.from(new Map(worksiteData.map(item => [item.value, item])).values());
      if (uniqueArray?.length > 0) {
        uniqueArray.unshift({ value: '', label: 'All Stores' });
      }
      setWorksiteOptionsForFilter(uniqueArray);
    }

  }, [workSitesForCorporateData])

  useEffect(() => {
    if (workSitesForCorporateError && Object.keys(workSitesForCorporateError)?.length > 0) {
      dispatch(resetWorkSiteErrorData());
    }
  }, [workSitesForCorporateError]);

  useEffect(() => {
    if (postPhoneNumberData && Object.keys(postPhoneNumberData)?.length > 0) {
      setPhoneNumber('');
      setIsPhoneNumberVerified(false);
      toast.success('Phone number added successfully');
      setSlideUpVisible(false);
      dispatch(getAllPhoneNumbers(jwtToken, userId));
      dispatch(resetPostPhoneNumberData());
    }
  }, [postPhoneNumberData]);

  useEffect(() => {
    if (postPhoneNumberError && Object.keys(postPhoneNumberError)?.length > 0) {
      toast.error(postPhoneNumberError.message);
      dispatch(resetPostPhoneNumberErrorData());
    }
  }, [postPhoneNumberError]);

  useEffect(() => {
    if (editPhoneNumberData && Object.keys(editPhoneNumberData)?.length > 0) {
      toast.success('Phone number updated successfully');

      setPhoneNumber('');
      setIsPhoneNumberVerified('');
      setformWorksiteId('');
      setIsEditMode(false);
      setCurrentPhoneNumberId('');
      setSlideUpVisible(false);

      dispatch(getAllPhoneNumbers(jwtToken, userId));
      dispatch(resetEditPhoneNumberData());
    }
  }, [editPhoneNumberData]);

  useEffect(() => {
    if (editPhoneNumberError && Object.keys(editPhoneNumberError)?.length > 0) {
      toast.error(editPhoneNumberError.message);
      dispatch(resetEditPhoneNumberErrorData());
    }
  }, [editPhoneNumberError]);

  useEffect(() => {
    if (deletePhoneNumberData && Object.keys(deletePhoneNumberData)?.length > 0) {
      toast.success('Phone number deleted successfully');
      dispatch(getAllPhoneNumbers(jwtToken, userId));
      dispatch(resetDeletePhoneNumberData());
    }
  }, [deletePhoneNumberData]);

  useEffect(() => {
    if (deletePhoneNumberError && Object.keys(deletePhoneNumberError)?.length > 0) {
      toast.error(deletePhoneNumberError.message);
      dispatch(resetDeletePhoneNumberErrorData());
    }
  }, [deletePhoneNumberError]);

  // Slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState('500');
  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);
  const [viewData, setViewData] = useState(false);
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth('600');
    if (slideUpModalSize[1]) setSlideUpWidth('500');
    if (slideUpModalSize[2]) setSlideUpWidth('300');
  }, [slideUpModalSize]);

  const handleSearchChange = (value, type) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: value
    }));
  };

  useEffect(() => {
    setExportPhoneNumberData(nullToEmpty(phoneNumberWithIndex))
  }, [phoneNumberWithIndex]);

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'createdAt') && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };

    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  };

  const handleInputChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setIsPhoneNumberVerified(value);
  }

  const handleAddOrEdit = () => {
    if (!formWorksiteId || formWorksiteId?.length === 0) {
      return toast.error('Please select the worksite');
    }

    const phoneNumberRegex = /^\+?\d+$/;
    const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
    if (formattedPhoneNumber === '' || !phoneNumberRegex.test(formattedPhoneNumber)) {
      return toast.error('Please enter a valid phone number');
    }
    if (isEditMode) {
      dispatch(editPhoneNumber(jwtToken, currentPhoneNumberId, { phoneNumber: formattedPhoneNumber, verified: isPhoneNumberVerified, worksiteId: formWorksiteId }));
    } else {
      dispatch(postPhoneNumber(jwtToken, { phoneNumber: formattedPhoneNumber, verified: isPhoneNumberVerified, worksiteId: formWorksiteId, corporateId: userId }));
    }
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setPhoneNumber('');
    setIsPhoneNumberVerified(false);
    setSlideUpVisible(true);
  }

  const handleEditClick = (row) => {
    setPhoneNumber(row.phoneNumber);
    setIsPhoneNumberVerified(row.verified);
    setformWorksiteId(row.worksiteId)
    setIsEditMode(true);
    setCurrentPhoneNumberId(row.id);
    setSlideUpVisible(true);
  };

  const handleDeleteClick = (row) => {
    setCurrentPhoneNumberId(row.id);
    setSlideUpVisibleForDelete(true);
  };

  const handleDelete = () => {
    if (currentPhoneNumberId) {
      dispatch(deletePhoneNumber(jwtToken, currentPhoneNumberId));
      setSlideUpVisibleForDelete(false);
    }
  };

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true)
  };


  const tableColumns = [
    {
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // formatter: (cell, row, rowIndex) => rowIndex + 1,
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (currentPage - 1) * sizePerPage + rowIndex + 1; // Adjust row number based on current page
        return rowNumber;
      },
      csvExport: false,
      sortable: false,
      style: { width: '50px' },
    },
    {
      dataField: 'worksite.storeName',
      text: 'Store Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell) => cell || '',
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'verified',
    //   text: 'Verified',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    //   formatter: (cell, row) => (
    //     <div>
    //       {cell === 1 ? (
    //         <div className="btn btn-outline-success rounded-circle cusicon">
    //           <i className="pg-icon">tick</i>
    //         </div>
    //       ) : (
    //         <div className="btn btn-outline-danger rounded-circle cusicon">
    //           <i className="pg-icon">close</i>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
    {
      dataField: 'verified',
      text: 'Verified',
      sort: true,
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row) => {
        // Apply custom rendering logic for the table
        return (
          <div>
            {cell === 1 ? (
              <div className="btn btn-outline-success rounded-circle cusicon">
                <i className="pg-icon">tick</i>
              </div>
            ) : (
              <div className="btn btn-outline-danger rounded-circle cusicon">
                <i className="pg-icon">close</i>
              </div>
            )}
          </div>
        );
      },
      csvExport: true,  // Enable export for this field
      csvFormatter: (cell, row) => {
        // Use plain text for export (no custom UI)
        return cell === 1 ? 'Yes' : 'No';
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      // sortCaret: (order, column) => {
      //   if (!order) return <span>&nbsp;&uarr;&darr;</span>;
      //   else if (order === 'asc') return <span>&nbsp;&uarr;</span>;
      //   else if (order === 'desc') return <span>&nbsp;&darr;</span>;
      //   return null;
      // },
      formatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          });
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      }
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      csvProps: false,
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button
            style={{ backgroundColor: 'green', borderColor: 'green', color: 'white' }}
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleViewClick(row)}
          >
            <i className="pg-icon"><RiShareBoxFill color='white' /></i>
          </Button>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  const getFileName = () => {
    switch (location.pathname) {
      case '/corporate/phone-number-verified':
        return 'VerifiedPhoneNumbers.csv';
      case '/corporate/phone-number-unverified':
        return 'UnverifiedPhoneNumbers.csv';
      case '/corporate/phone-number':
        return 'AllPhoneNumbers.csv';
      default:
        return 'default-data.csv';
    }
  };

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-between mb-3">
                  <h3 class="text-primary no-margin text-uppercase">{location?.pathname === "/corporate/phone-number-verified" ? "Verified Phone Numbers" : (location?.pathname === "/corporate/phone-number-unverified") ? "Unverified Phone Numbers" : "All Phone Numbers"}</h3>
                  <div className="d-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-between mt-2 mt-md-0">
                    <button aria-label="" className="btn btn-primary m-r-10 mb-2 mb-md-0" type="button" onClick={handleAddClick}>
                      + Add Phone Number
                    </button>
                    {/* {csvPropsRef?.current && phoneNumberWithIndex?.length > 0 && (
                      <ExportCSVButton {...csvPropsRef?.current}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Phone Number CSV</ExportCSVButton>
                    )} */}
                    <ExportCSVButton
                      {...csvPropsRef?.current}
                      disabled={!phoneNumberWithIndex?.length}
                      className={phoneNumberWithIndex?.length ? "btn-enabled" : "btn-disabled"}
                    >
                      <i className="pg-icon m-r-10"><FiShare size={'15'} /></i>
                      Export Phone Number CSV
                    </ExportCSVButton>
                  </div>
                </div>

                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT d-flex align-items-center">
                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body mt-2">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block mt-2 mt-md-0" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">

                                <h5>{isEditMode ? 'Edit Phone Number' : 'Add Phone Number'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body px-4 px-md-5 py-4">
                                <form role="form">
                                  <div className="w-100 mb-3">
                                    <GroupSelect
                                      inputId='worksiteId'
                                      value={formWorksiteId}
                                      options={worksiteOptions}
                                      onChange={(selectedOption) => setformWorksiteId(selectedOption?.value)}
                                    />
                                  </div>
                                  <div class="input-group">
                                    <input type="text" className="form-control" value={phoneNumber} onChange={handleInputChange} placeholder="Enter phone number" />
                                    <span class={isPhoneNumberVerified ? "input-group-text bg-success text-white" : "input-group-text  bg-secondary"} id="basic-addon2">
                                      <label className="form-check-label mt-1" htmlFor="verifiedCheckbox">
                                        <input type="checkbox" className="form-check-input mr-2" id="verifiedCheckbox" checked={isPhoneNumberVerified} onChange={handleCheckboxChange} />
                                        {isPhoneNumberVerified ? "Verified" : 'Verify'}
                                      </label>
                                    </span>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleAddOrEdit}>
                                  {isEditMode ? 'Update' : '+ Add'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisibleForViewData ?
                        <SlideUpModal visible={slideUpVisibleForViewData} width={"600"} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForViewData(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Phone Number Details</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForViewData(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body py-3 h100vh">
                                <div className="row">

                                  <div className="col-md-6">

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Store Name</p>
                                          {viewData.worksite.storeName ?
                                            <p class="bold mb-0">{viewData.worksite.storeName}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                          {/* {JSON.stringify(viewData.worksite.storeName)}/ */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Verified</p>
                                          <div>
                                            {viewData.verified === 1 ? (
                                              <p class="bold mb-0">{"Verified"}</p>
                                            ) : (
                                              <p class="bold mb-0">{"UnVerified"}</p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="col-md-6">

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Phone Number</p>
                                          {viewData.phoneNumber ?
                                            <p class="bold mb-0">{viewData.phoneNumber}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Created At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.createdAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal>
                        : null
                      }

                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-start">
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by Phone No" value={filters.phoneNo} onChange={(e) => handleSearchChange(e.target.value, 'phoneNo')} />
                </div>
                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <GroupSelect
                    inputId='worksiteId'
                    value={worksiteId}
                    options={worksiteOptionsForFilter}
                    onChange={(selectedOption) => setWorksiteId(selectedOption?.value)}
                  />
                </div>
              </div>

              {!isLoading ? <div className="card-body custable">
                {phoneNumberWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportPhoneNumberData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: getFileName(),
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
