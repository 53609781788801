import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

import Button from '../../UIElements/Button/UtilsButton';
import PageContainer from '../../UIElements/Containers';
import SlideUpModal from '../../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import GroupSelect from "../../Forms/Elements/GroupSelect";
import {
  getWorksiteCheckinVisits,
  postCallRecordByWorksite,
  updateCallRecordByWorksite,
  deleteCallRecordByWorksite,
  resetUpdateCheckInCallRecordData,
  resetUpdateCheckInCallRecordError,
  resetWorkSiteCallRecordsErrorData,
  resetPostCallRecordData,
  resetPostCallRecordErrorData,
  resetEditCallRecordErrorData,
  resetDeleteCallRecordData,
  resetDeleteCallRecordErrorData,
  getCallRecordingUrl,
  resetUpdateCallRecordErrorData,
} from '../../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { RiShareBoxFill } from 'react-icons/ri';
import { FiShare } from 'react-icons/fi';
import Loader from '../../Loader/Loader';
import NoData from '../../NoData/NoData';
import { nullToEmpty } from '../../../utils/csvExport';
import { Link } from 'react-router-dom';

const CallRecords = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const callRecordEmptyState = {
    workerName: '',
    workerDob: '',
    workerStatus: '',
    checkOut: '',
  };
  const newCallRecordEmptyState = {
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };
  const workerStatusFilterOptions = [{ value: 'all', label: 'All Status' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'completed', label: 'Completed' }, { value: 'requires authorization', label: 'Requires Authorization' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires part', label: 'Requires Part' }]
  const workerStatusUpdateOptions = [{ value: 'additional work found', label: 'Additional Work Found' }, { value: 'completed', label: 'Completed' }, { value: 'requires authorization', label: 'Requires Authorization' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires part', label: 'Requires Part' }]
  const siteFilterOptions = [{ value: 'all sites', label: 'All Sites' }, { value: '0', label: 'Active Sites' }, { value: '1', label: 'InActive Sites' }]
  // let endDate = new Date();
  // endDate.setDate(endDate.getDate() + 30);

  let endDate = new Date(); // Current date
  let startDate = new Date(); // Create a new date object
  startDate.setMonth(startDate.getMonth() - 6);

  const dispatch = useDispatch();
  const callRecords = useSelector((state) => state.WorkSiteData.getWorksiteCheckinRecords);
  const callRecordsErrorData = useSelector((state) => state.WorkSiteData.getWorksiteCallRecordsError);

  const postCallRecordData = useSelector((state) => state.WorkSiteData.postCallRecordData);
  const postCallRecordError = useSelector((state) => state.WorkSiteData.postCallRecordError);
  const editCallRecordData = useSelector((state) => state.WorkSiteData.updateCallRecordData);
  const editCallRecordError = useSelector((state) => state.WorkSiteData.updateCallRecordError);
  const deleteCallRecordData = useSelector((state) => state.WorkSiteData.deleteCallRecordData);
  const deleteCallRecordError = useSelector((state) => state.WorkSiteData.deleteCallRecordError);

  const [filters, setFilters] = useState({
    searchValue: '',
    workOrderNumber: '',
    phoneNumber: '',
    workerName: '',
    workerStatus: '',
    technicianPhoneNumber: ''
  });

  const worksiteId = useSelector((state) => state.AdminAuthData.userId);

  const [callRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [startDateRange, setStartDateRange] = useState(startDate);
  const [endDateRange, setEndDateRange] = useState(endDate);
  const [currentCallRecordId, setCurrentCallRecordId] = useState(null);
  const [callRecord, setCallRecord] = useState(callRecordEmptyState)
  const [newCallRecord, setNewCallRecord] = useState(newCallRecordEmptyState);
  const [workerStatus, setWorkerStatus] = useState('');
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [exportCheckInVisitsData, setExportCheckInVisitsData] = useState()

  const [audioUrl, setAudioUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState('');
  const [audioSlideUpVisible, setAudioSlideUpVisible] = useState(false);
  const [name, setName] = useState("");
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);
  const [manualInput, setManualInput] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [sites, setSites] = useState('0');

  // GET Actions
  useEffect(() => {
    setIsLoading(true)
    if (worksiteId) {
      dispatch(getWorksiteCheckinVisits(jwtToken, worksiteId));
    }
  }, [worksiteId]);

  useEffect(() => {
    if (recordingUrl?.length > 0 && !audioSlideUpVisible) {
      const fetchRecording = async () => {
        try {
          const jwtToken = localStorage.getItem('jwtToken');
          setAudioSlideUpVisible(true);
          const url = await dispatch(getCallRecordingUrl(jwtToken, recordingUrl));
          if (url?.data) {
            const audioBlob = new Blob([url?.data], { type: 'audio/mpeg' });
            const audioObjectUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioObjectUrl);
            setRecordingUrl('');
          } else {
            toast.error('Try again later');
            setAudioSlideUpVisible(false);
            setRecordingUrl("");
            setAudioUrl("");
            setName("");
          }
        } catch (error) {
          // toast.error('Try again later');
        }
      };
      fetchRecording();
    }
  }, [recordingUrl, audioSlideUpVisible, dispatch]);


  useEffect(() => {
    const data = callRecords.map((record, i) => ({ ...record, index: i + 1 }));
    setCallRecordsWithIndex(data);
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [callRecords]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetWorkSiteCallRecordsErrorData());
      setIsLoading(false);
    }
  }, [callRecordsErrorData]);

  // POST Actions
  useEffect(() => {
    if (postCallRecordData && Object.keys(postCallRecordData)?.length > 0) {
      toast.success('Call record added successfully');
      setSlideUpVisibleForCreate(false);
      setNewCallRecord(newCallRecordEmptyState)
      dispatch(getWorksiteCheckinVisits(jwtToken, worksiteId));
      dispatch(resetPostCallRecordData());
    }
  }, [postCallRecordData]);

  useEffect(() => {
    if (postCallRecordError && Object.keys(postCallRecordError)?.length > 0) {
      toast.error(postCallRecordError.message);
      dispatch(resetPostCallRecordErrorData());
    }
  }, [postCallRecordError]);

  // PATCH Actions
  useEffect(() => {
    if (editCallRecordData && Object.keys(editCallRecordData)?.length > 0) {
      setCallRecord(callRecordEmptyState);
      toast.success('Call record updated successfully');
      setSlideUpVisible(false);
      dispatch(getWorksiteCheckinVisits(jwtToken, worksiteId));
      dispatch(resetUpdateCheckInCallRecordData())
    }
  }, [editCallRecordData]);

  useEffect(() => {
    if (editCallRecordError && Object.keys(editCallRecordError)?.length > 0) {
      toast.error(editCallRecordError.message);
      dispatch(resetUpdateCheckInCallRecordError());
    }
  }, [editCallRecordError]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    const { workOrderNumber, phoneNumber, workerName, technicianPhoneNumber } = filters; // Extract phoneNumber and workerName filters

    let filteredData = callRecords;

    // Apply worksiteId filter if provided
    if (worksiteId?.length > 0) {
      filteredData = filteredData.filter(data => data.worksiteId === worksiteId);
    }

    if (startDateRange && endDateRange) {
      filteredData = filteredData.filter(data => {
        const createdAtDate = new Date(data.checkIn);
        const adjustedEndDateRange = new Date(endDateRange);
        adjustedEndDateRange.setHours(23, 59, 59, 999);
        return createdAtDate >= startDateRange && createdAtDate <= adjustedEndDateRange;
      });
    }

    // Apply phoneNumber filter if provided
    if (workOrderNumber) {
      filteredData = filteredData.filter(data =>
        data.workOrderNumber?.toLowerCase().includes(workOrderNumber.toLowerCase())
      );
    }

    // Apply phoneNumber filter if provided
    if (technicianPhoneNumber) {
      filteredData = filteredData.filter(data =>
        data.workerDob?.toLowerCase().includes(technicianPhoneNumber.toLowerCase())

      );
    }

    // Apply workerName filter if provided
    if (workerName) {
      filteredData = filteredData.filter(data =>
        data.workerName?.toLowerCase().includes(workerName.toLowerCase())
      );
    }

    if (workerStatus && workerStatus !== 'all') {
      filteredData = filteredData.filter(data => data.workerStatus === workerStatus);
    }

    if (sites === '0') {
      filteredData = filteredData.filter(data => data.isDeleted === 0);
    } else if (sites === '1') {
      filteredData = filteredData.filter(data => data.isDeleted === 1);
    }


    // Add index and update state
    setCallRecordsWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 })));
  }, [filters, callRecords, worksiteId, workerStatus, startDateRange, endDateRange, sites]);


  useEffect(() => {
    if (startDateRange && endDateRange) {
      setManualInput(`${moment(startDateRange).format("MM/DD/YYYY")} - ${moment(endDateRange).format("MM/DD/YYYY")}`);
    }
  }, [startDateRange, endDateRange]);


  const handleDateRange = (event, picker) => {
    setStartDateRange(picker.startDate.toDate());
    setEndDateRange(picker.endDate.toDate());

  };

  const handleManualChange = (e) => {
    const value = e.target.value;
    setManualInput(value); // Update the manual input field

    // Check if input format is valid and then update date range
    const dates = value.split(" - ");
    if (dates.length === 2) {
      const start = moment(dates[0], "MM/DD/YYYY", true);
      const end = moment(dates[1], "MM/DD/YYYY", true);

      if (start.isValid() && end.isValid()) {
        setStartDateRange(start);
        setEndDateRange(end);
      }
    }
  };

  useEffect(() => {
    if (deleteCallRecordData && Object.keys(deleteCallRecordData)?.length > 0) {
      toast.success('Call record data deleted successfully');
      dispatch(getWorksiteCheckinVisits(jwtToken, worksiteId));
      dispatch(resetDeleteCallRecordData());
    }
  }, [deleteCallRecordData]);

  useEffect(() => {
    if (deleteCallRecordError && Object.keys(deleteCallRecordError)?.length > 0) {
      toast.error(deleteCallRecordError.message);
      dispatch(resetDeleteCallRecordErrorData());
    }
  }, [deleteCallRecordError]);

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  // Create call records modal
  const [slideUpVisibleForCreate, setSlideUpVisibleForCreate] = useState(false);
  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);
  const [viewData, setViewData] = useState(false);

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        worksiteId: worksiteId,
        [e.name]: selectOption
      }));
    } else {
      const { name, value } = e.target;
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        // worksiteId: worksiteId,
        [name]: value
      }));
    }
  };

  const handleCheckoutClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisible(true);
    setCallRecord({
      workerName: row?.workerName,
      workerDob: row?.workerDob,
    });
  }

  const handleClickUrlOne = (row) => {
    if (!audioSlideUpVisible) {
      setRecordingUrl(row.checkInRecordingUrl);
      setName(row.workerName);
    }
  };



  const closeModal = () => {
    setName('');
    setAudioUrl('');
    setAudioSlideUpVisible(false);
  };

  const handleAddCallRecords = async () => {
    setSlideUpVisibleForCreate(true)
  }

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true)
  };

  const handleEdit = () => {
    const missingFields = [];
    if (!callRecord.workerName) missingFields.push('Worker name');
    if (!callRecord.workerDob) missingFields.push('Technician phone no');
    if (!callRecord.workerStatus) missingFields.push('Worker status');
    if (!callRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      toast.error('Please fill all required fields')
    }

    if (missingFields?.length === 0) {
      dispatch(updateCallRecordByWorksite(jwtToken, currentCallRecordId, callRecord));
    }
  };

  const tableColumns = [
    {
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // formatter: (cell, row, rowIndex) => rowIndex + 1,
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (currentPage - 1) * sizePerPage + rowIndex + 1; // Adjust row number based on current page
        return rowNumber;
      },
      csvExport: false,
      sortable: false,
      style: { width: '50px' },
    },
    // {
    //   dataField: 'createdAt',
    //   text: 'Date',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    //   formatter: (cell) => {
    //     if (cell) {
    //       return new Date(cell).toLocaleString('en-US', {
    //         year: 'numeric',
    //         month: 'numeric',
    //         day: 'numeric',
    //         // hour: '2-digit',
    //         // minute: '2-digit',
    //         // second: '2-digit',
    //         // hour12: true,
    //       });
    //     } else {
    //       return '';
    //     }
    //   },
    // },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const workOrderA = BigInt(a.replace(/\D/g, ''));
        const workOrderB = BigInt(b.replace(/\D/g, ''));

        if (order === 'asc') {
          return workOrderA > workOrderB ? 1 : -1;
        }
        if (order === 'desc') {
          return workOrderA < workOrderB ? 1 : -1;
        }
      },
      formatter: (cell, row) => {
        return (
          <Link to={`/worksite/work-order/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
    },
    {
      dataField: 'checkIn',
      text: 'Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).setHours(0, 0, 0, 0); // Strip the time part for Date sorting
        const dateB = new Date(b).setHours(0, 0, 0, 0); // Strip the time part for Date sorting

        if (order === 'asc') {
          return dateA - dateB;
        }
        return dateB - dateA;
      },
      formatter: (cell) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'checkIn',
      text: 'Check In',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const timeA = new Date(a).getHours() * 60 + new Date(a).getMinutes(); // Extract time part (hours and minutes)
        const timeB = new Date(b).getHours() * 60 + new Date(b).getMinutes(); // Extract time part (hours and minutes)

        if (order === 'asc') {
          return timeA - timeB;
        }
        return timeB - timeA;
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  marginTop: '7px',
                }}
                onClick={() => handleClickUrlOne(row)}
              >
                <div>
                  {row?.checkInRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>
                  {row?.checkInCallDuration
                    ? `${row?.checkInCallDuration} sec`
                    : ''}
                </div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'workerName',
      // text: 'Check-in Technician Name',
      text: (
        <span>
          Check-In<br />
          Technician Name
        </span>
      ),
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvText: 'Check-In Technician Name',
    },
    {
      dataField: 'workerDob',
      text: 'Technician Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,

    },
    // {
    //   dataField: 'phoneNumber',
    //   text: 'Phone No',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    // {
    //   dataField: 'workerStatus',
    //   text: 'Status',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   formatter: (cell) => {
    //     if (typeof cell === 'string') {
    //       // Define the status to className map
    //       const statusClassMap = {
    //         'Completed': 'badge bg-success text-white fw-lighter p-2',
    //         'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
    //         'Requires Part': 'badge bg-secondary fw-lighter p-2',
    //         'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
    //         'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
    //       };

    //       // Get the capitalized status
    //       const capitalizedStatus = cell
    //         .split(' ')
    //         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //         .join(' ');

    //       // Determine the appropriate className based on the status
    //       const className = statusClassMap[capitalizedStatus] || 'badge bg-dark'; // Default class
    //       return <span className={className}>{capitalizedStatus}</span>;
    //     }
    //     return cell;
    //   },
    //   // sort: true,
    // },

    {
      dataField: 'checkOut',
      text: 'Check Out',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => {
        return (
          <Button
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleCheckoutClick(row)}
          >
            Check out
          </Button>
        );
      },
    },
  ];

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  useEffect(() => {
    setExportCheckInVisitsData(nullToEmpty(callRecordsWithIndex))
  }, [callRecordsWithIndex])

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  // const options = {
  //   sizePerPageRenderer
  // };  

  const options = {
    page: currentPage, // Ensure currentPage is used
    sizePerPage: sizePerPage, // Set the current size per page
    onSizePerPageChange: (sizePerPage, page) => {
      setSizePerPage(sizePerPage);
      setCurrentPage(page); // Update the page when size changes
    },
    onPageChange: (page) => setCurrentPage(page),
    sizePerPageRenderer,
  };

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-between mt-md-0 mb-3">
                  <h3 className="text-primary no-margin text-uppercase">Check-in Visits</h3>
                  <div className='row gap-2 px-3'>
                    <div className="mt-2 mt-md-0">
                      {/* {csvPropsRef?.current && (
                        <ExportCSVButton   {...csvPropsRef?.current}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Check-in Visits CSV</ExportCSVButton>
                      )} */}
                      <ExportCSVButton
                        {...csvPropsRef?.current}
                        disabled={!callRecordsWithIndex?.length}
                        className={callRecordsWithIndex?.length ? "btn-enabled" : "btn-disabled"}
                      >
                        <i className="pg-icon m-r-10"><FiShare size={'15'} /></i>
                        Export Check-in Visits CSV
                      </ExportCSVButton>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap justify-content-start'>
                <div className="input-group p-l-0 col-lg-3 col-md-6 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by Work Order No" name='workOrderNumber' value={filters.workOrderNumber} onChange={handleSearchChange} />
                </div>
                <div className="input-group p-l-0 col-lg-3 col-md-6 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by Technician Name" name='workerName' value={filters.workerName} onChange={handleSearchChange} />
                </div>
                <div className="input-group p-l-0 col-lg-3 col-md-6 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by Technician Phone No"
                    value={filters.technicianPhoneNumber}
                    name='technicianPhoneNumber'
                    onChange={handleSearchChange}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-3 mb-md-0 px-1">
                  <DateRangePicker
                    startDate={startDateRange}
                    onApply={handleDateRange} // Handle date picker change
                    focusedInput={focusedInput} // Track which input is focused
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)} // Handle focus change
                    endDate={endDateRange}
                    showTimeSelect
                  >
                    <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                      <span className="input-group-text input-date-icon-pre">
                        <i className="pg-icon">calendar</i>
                      </span>
                      <input
                        type="text"
                        name="reservation"
                        id="daterangepicker"
                        className="form-control"
                        style={{ width: "250px", height: "35px" }}
                        value={manualInput}
                        onChange={handleManualChange} // Allow manual input change
                      />
                    </div>
                  </DateRangePicker>
                </div>

                <div className="input-group col-md-3 col-sm-6 col-xs-12 p-l-0 mb-2">
                  <div className="input-group ">
                    <GroupSelect
                      placeholder='All Sites'
                      inputId='worksiteId'
                      value={sites}
                      options={siteFilterOptions}
                      onChange={(selectedOption) => setSites(selectedOption?.value)}
                    />
                  </div>
                </div>
              </div>

              {slideUpVisible ?
                <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                  <div className="modal-content-wrapper">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5>Checkout Call Record</h5>
                        <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                          <i className="pg-icon">close</i>
                        </button>
                      </div>
                      <div className="modal-body px-4 px-md-5 py-4">
                        <form role="form">
                          <div class="input-group flex-column">
                            <div className="row">
                              <div className="col-md-4">
                                <label for='workerName'>Technician Name<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control w-100" placeholder='Update Worker Name' value={callRecord.workerName} onChange={handleInputChange} name='workerName' id='workerName' />
                              </div>
                              <div className="col-md-4">
                                <label for='workerDob' className='mt-3 mt-md-0'>Technician Phone No<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="form-control w-100" placeholder='Update Technician Phone No' value={callRecord.workerDob} disabled name='workerDob' id='workerDob' />
                              </div>

                              <div className="col-md-4">
                                <label htmlFor='workerStatus' className='mt-3 mt-md-0'>Worker Status<span style={{ color: 'red' }}>*</span></label>
                                <GroupSelect
                                  inputId='workerStatus'
                                  value={callRecord.workerStatus}
                                  options={workerStatusUpdateOptions}
                                  onChange={(selectedOption) => handleInputChange({ name: 'workerStatus' }, selectedOption?.value)}
                                />
                              </div>
                            </div>

                            <label for='checkOut' className='mt-3'>Check Out<span style={{ color: 'red' }}>*</span></label>
                            <DatePicker
                              onChange={(selectedOption) => handleInputChange({ name: 'checkOut' }, selectedOption)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                              placeholderText="Select date and time"
                              className="form-control w-100"
                              selected={callRecord.checkOut}
                              name='checkOut'
                              id='checkOut'
                            />
                          </div>
                        </form>
                      </div>

                      <div className='modal-footer'>
                        <button aria-label="" type="button" className="btn btn-primary mt-3" onClick={handleEdit}>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </SlideUpModal>
                : null
              }

              {audioSlideUpVisible ? (
                <SlideUpModal visible={audioSlideUpVisible} width={'400'} effect="fadeInUp" onClickAway={() => closeModal()}>
                  <div className="modal-content-wrapper">
                    <div className="modal-content">
                      <div className="modal-top">
                        <button
                          aria-label=""
                          type="button"
                          className="close"
                          onClick={() => closeModal()}
                          aria-hidden="true"
                        >
                          <i className="pg-icon">close</i>
                        </button>
                        <h4 className="text-primary no-margin text-uppercase text-center">{name}</h4>
                      </div>
                      {audioUrl?.length > 0 ? (
                        <div className="modal-body">
                          <form role="form">
                            <div className="form-group-attached">
                              <div className="row justify-content-center">
                                <audio controls style={{ margin: "0", height: '40px' }}>
                                  <source src={audioUrl} type="audio/mpeg" />
                                </audio>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <div className="modal-body">
                          <div className="row justify-content-center">
                            <div class="spinner-grow" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </SlideUpModal>
              )
                : null
              }
              {!isLoading ? <div className="card-body custable">
                {callRecordsWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportCheckInVisitsData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'CheckInVisits.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default CallRecords;
