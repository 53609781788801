
import CorporateDataActions from '../redux/actions/CorporateDataAction';
import apiAdminWithJwt from '../services/apiAdminWithJwt';

// GET Methods
export const getAllCallRecords = (token, corporateId) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-call-record-for-corporate?corporateId=${corporateId}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_CALL_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_CALL_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getRecentCallRecords = (token, corporateId) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-recent-call-record-for-corporate?corporateId=${corporateId}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_RECENT_CALL_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_RECENT_CALL_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getDeletedlCallRecords = (token, corporateId) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-deleted-call-record-for-corporate?corporateId=${corporateId}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_DELETED_CALL_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_DELETED_CALL_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getAllWorkOrders = (token, corporateId) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-work-order-for-corporate?corporateId=${corporateId}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_WORK_ORDERS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_WORK_ORDERS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getAllPhoneNumbers = (token, corporateId) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-phone-number-for-corporate?corporateId=${corporateId}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_PHONE_NUMBERS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_PHONE_NUMBERS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getAllCorporateRecords = (token) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get('admin/get-all-corporate-users',)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_CORPORATE_RECORDS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_ALL_CORPORATE_RECORDS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCorporateRecord = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-single-corporate-user/${id}`,)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCallRecordingUrl = (token, recordingUrl) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
      return await apiWithToken
        .get(
          `corporate/get-recording-url?recordingUrl=${encodeURIComponent(
            recordingUrl
          )}`,
          { responseType: 'blob' }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error;
        });
  };
};

export const getWorkSitesForCorporate = (token, id, isInActive) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-worksite-for-corporate?corporateId=${id}&isInActive=${isInActive}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_WORK_SITES_FOR_CORPORATE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_WORK_SITES_FOR_CORPORATE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getInActiveWorkSitesForCorporate = (token, id, isInActive) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-worksite-for-corporate?corporateId=${id}&isInActive=${isInActive}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCallRecordsByWorkOrderNumber = (token, corporateId, workOrderNumber, worksiteId) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`corporate/get-call-records-by-work-order-number?corporateId=${corporateId}&workOrderNumber=${workOrderNumber}&worksiteId=${worksiteId}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCorporateCompletedVisits = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/corporate/reports/get-corporate-completed-visits?corporateId=${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_COMPLETED_VISITS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_COMPLETED_VISITS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCorporateCheckInVisits = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/corporate/reports/get-corporate-check-in-visits?corporateId=${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_CHECK_IN_VISITS_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_CHECK_IN_VISITS_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const getCorporateVisitsSummary = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .get(`/corporate/reports/get-corporate-visits-summary?corporateId=${id}`)
      .then((res) => {
        
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_VISITS_SUMMARY_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.GET_CORPORATE_VISITS_SUMMARY_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// POST Methods
export const postCorporateRecord = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('admin/create-corporate-user', data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.POST_CORPORATE_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.POST_CORPORATE_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postCallRecord = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-one-call-record', data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.POST_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.POST_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postPhoneNumber = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-phone-number', data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.POST_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.POST_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorkOrderNumber = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-work-order-number', data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.POST_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.POST_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorkOrderNumberUsingCsv = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-work-order-number-using-csv', data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const postWorksiteUsingCsv = (token, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .post('corporate/create-work-site-using-csv', data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.POST_CORPORATE_WORK_SITE_USING_CSV_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.POST_CORPORATE_WORK_SITE_USING_CSV_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// EDIT Methods
export const editCorporateRecordByAdmin = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`admin/edit-corporate-user/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.EDIT_CORPORATE_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.EDIT_CORPORATE_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editRecordByCorporate = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-corporate-user/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.EDIT_CORPORATE_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.EDIT_CORPORATE_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editCallRecord = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-call-record/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.EDIT_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.EDIT_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const updateCheckInCallRecord = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/reports/update-check-in-call-record/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.UPDATE_CHECK_IN_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.UPDATE_CHECK_IN_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editPhoneNumber = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-phone-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.EDIT_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.EDIT_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editWorkOrderNumber = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-work-order-number/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.EDIT_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.EDIT_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editWorkSite = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate/edit-worksite/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.EDIT_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.EDIT_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const editPassword = (token, id, data) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .patch(`corporate-auth/password-reset/${id}`, data)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.EDIT_CORPORATE_PASSWORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.EDIT_CORPORATE_PASSWORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// DELETE Methods
export const deleteCorporateRecord = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`admin/delete-corporate-user/${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.DELETE_CORPORATE_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.DELETE_CORPORATE_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteCallRecord = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/delete-call-record/${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.DELETE_CALL_RECORD_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.DELETE_CALL_RECORD_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deletePhoneNumber = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/delete-phone-number/${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.DELETE_PHONE_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.DELETE_PHONE_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteWorkOrderNumber = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/delete-work-order-number/${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.DELETE_WORK_ORDER_NUMBER_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.DELETE_WORK_ORDER_NUMBER_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const deleteWorkSite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/delete-worksite/${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.DELETE_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.DELETE_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const inActiveWorkSite = (token, id) => {
  return async (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token);
    await apiWithToken
      .delete(`corporate/inactive-worksite/${id}`)
      .then((res) => {
        dispatch({
          type: CorporateDataActions.INACTIVE_WORK_SITE_DATA,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CorporateDataActions.INACTIVE_WORK_SITE_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

// POST Resets
export const resetPostCorporateRecordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CORPORATE_RECORD_DATA, payload: {} });
  };
};

export const resetPostCorporateRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CORPORATE_RECORD_ERROR, payload: {} });
  };
};

export const resetPostCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetPostCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetPostPhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetPostPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetPostWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetPostWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetPostWorkOrderNumberUsingCsvData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_DATA, payload: {} });
  };
};

export const resetPostWorkOrderNumberUsingCsvError = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CORPORATE_WORK_ORDER_NUMBERS_USING_CSV_ERROR, payload: {} });
  };
};

export const resetPostWorksiteUsingCsvData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CORPORATE_WORK_SITE_USING_CSV_DATA, payload: {} });
  };
};

export const resetPostWorksiteUsingCsvError = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.POST_CORPORATE_WORK_SITE_USING_CSV_ERROR, payload: {} });
  };
};


// EDIT Resets
export const resetEditCorporateRecordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_CORPORATE_RECORD_DATA, payload: {} });
  };
};

export const resetEditCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetEditCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetUpdateCheckInCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.UPDATE_CHECK_IN_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetUpdateCheckInCallRecordError = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.UPDATE_CHECK_IN_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetEditPhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetEditPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetEditWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetEditWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetEditCorporateUpdatePasswordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_CORPORATE_PASSWORD_DATA, payload: {} });
  };
};

export const resetCorporateUpdatePasswordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_CORPORATE_PASSWORD_ERROR, payload: {} });
  };
};

// DELETE Resets
export const resetDeleteCorporateRecordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_CORPORATE_RECORD_DATA, payload: {} });
  };
};

export const resetDeleteCorporateRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_CORPORATE_RECORD_ERROR, payload: {} });
  };
};

export const resetDeleteCallRecordData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_CALL_RECORD_DATA, payload: {} });
  };
};

export const resetDeleteCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_CALL_RECORD_ERROR, payload: {} });
  };
};

export const resetDeletePhoneNumberData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_PHONE_NUMBER_DATA, payload: {} });
  };
};

export const resetDeletePhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_PHONE_NUMBER_ERROR, payload: {} });
  };
};

export const resetDeleteWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetDeleteWorkOrderNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetDeleteWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_WORK_SITE_DATA, payload: {} });
  };
};

export const resetDeleteWorkSiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.DELETE_WORK_SITE_ERROR, payload: {} });
  };
};

export const resetInActiveWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.INACTIVE_WORK_SITE_DATA, payload: {} });
  };
};

export const resetInActiveWorkSiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.INACTIVE_WORK_SITE_ERROR, payload: {} });
  };
};

// GET Resets
export const resetCorporateRecordsErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_ALL_CORPORATE_RECORDS_ERROR, payload: {} });
  };
};

export const resetGetCorporateRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_CORPORATE_RECORD_ERROR, payload: {} });
  };
};

export const resetGetCorporateWorksiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_WORK_SITES_FOR_CORPORATE_ERROR, payload: {} });
  };
};

export const resetGetCorporateInActiveWorksiteErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_IN_ACTIVE_WORK_SITES_FOR_CORPORATE_ERROR, payload: {} });
  };
};

export const resetGetCorporateVisitsSummary = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_ALL_CALL_RECORDS_ERROR, payload: {} });
  };
};

export const resetGetCallRecordErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_CORPORATE_VISITS_SUMMARY_ERROR, payload: {} });
  };
};

export const resetGetCheckInCallRecordError = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_CORPORATE_CHECK_IN_VISITS_ERROR, payload: {} });
  };
};

export const resetGetWorkOrderErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_ALL_WORK_ORDERS_ERROR, payload: {} });
  };
};

export const resetGetPhoneNumberErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_ALL_PHONE_NUMBERS_ERROR, payload: {} });
  };
};

export const resetGetCallRecordsByWorkOrderNumberData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA, payload: {} });
  };
};

export const resetGetCallRecordsByWorkOrderNumberError = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR, payload: {} });
  };
};

export const resetEditWorkSiteData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_WORK_SITE_DATA, payload: {} });
  };
};

export const resetEditWorkSitesErrorData = () => {
  return (dispatch) => {
    dispatch({ type: CorporateDataActions.EDIT_WORK_SITE_ERROR, payload: {} });
  };
};